export const PageRoutes = {
  Home: '/home',
  Login: '/',
  RestorePassword: '/restore-password',
  Patients: '/patients',
  Exercises: '/exercises',
  Doctors: '/doctors',
  Patient: '/patient/:option/:id',
  Doctor: '/doctor/:id',
  History: '/history',
  AboutTraining: '/about-training/:id/',
  Training: 'training/:idTraining',
  TrainingsPlan: '/trainings-plan',
  Page404: '/*',
  Profile: '/profile',
  Achievements: '/achievements',
  DoctorTrainings: '/doctor-trainings',
  Info: '/information',
} as const
