import { defineStyleConfig } from '@chakra-ui/react'

export const TooltipTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 500,
    boxShadow: 'none',
    border: '1px solid #B0D4FF',
    borderRadius: '7px',
  },
})
