import { create } from 'zustand'
import { AssignmentsCalendarState } from '../types'

export const useAssignmentsCalendarStore = create<AssignmentsCalendarState>()(
  (set) => ({
    curDate: new Date(),
    curMonth: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    selectedAssignmentId: '',
    selectedAssignmentTitle: '',
    patientCurData: [],
    formattedCurData: [],
    selectedOptions: [],

    setCurDate: (date) => set({ curDate: date }),
    setCurMonth: (month) => set({ curMonth: month }),
    setSelectedAssignment: (id, title) =>
      set({ selectedAssignmentId: id, selectedAssignmentTitle: title }),
    setPatientCurData: (data) => set({ patientCurData: data }),
    setFormattedCurData: (formattedData) =>
      set({ formattedCurData: formattedData }),
    setSelectedOptions: (options) => set({ selectedOptions: options }),
  })
)
