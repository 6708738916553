import { create } from 'zustand'
import { TrainingState } from '../types'

export const useTrainingStore = create<TrainingState>()((set) => ({
  count: 0,
  countErrors: {},
  instructions: '',
  suggest: '',
  step: 0,
  time: null,
  isCamera: false,
  isCameraError: false,
  tabStatus: 'me',
  isFinish: false,
  isVideoInstructionOn: true,
  video: '',
  nextStep: false,
  setNextStep: () => set((state) => ({ nextStep: !state.nextStep })),
  addCountError: (errorMessage) =>
    set((state) => {
      const currentCount = state.countErrors[errorMessage] || 0
      return {
        countErrors: {
          ...state.countErrors,
          [errorMessage]: currentCount + 1,
        },
      }
    }),
  resetErrors: () => set({ countErrors: {} }),
  setTime: (time) => set(() => ({ time })),
  subtractTime: () => set((state) => ({ time: state.time ? state.time - 1 : null })),
  setInstructions: (instructions) => set(() => ({ instructions })),
  setSuggest: (suggest) => set(() => ({ suggest })),
  addCount: () => set((state) => ({ count: state.count + 1 })),
  clearCount: () => set(() => ({ count: 0 })),
  setStep: (step) => set(() => ({ step })),
  setCamera: (isCamera) => set(() => ({ isCamera })),
  setCameraError: (isCameraError) => set(() => ({ isCameraError })),
  setTabStatus: (tabStatus) => set(() => ({ tabStatus })),
  setFinish: (isFinish) => set(() => ({ isFinish })),
  setVideoInstruction: (isVideoInstructionOn) =>
    set(() => ({ isVideoInstructionOn })),
  setVideo: (video) => set(() => ({ video })),
}))
