import { ProtectedRoute } from 'app/lib/ProtectedRoute'
import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import { PageRoutes } from 'shared/config/pages/PageRoutes'
import { DefaultLayout } from 'shared/ui'
import { Menu } from 'widgets/index'

const LoginPage = lazy(() => import('./login'))
const RestorePassword = lazy(() => import('./restore-password'))
const HomePage = lazy(() => import('./home'))
const Error404 = lazy(() => import('./error-404'))
const History = lazy(() => import('./history'))
const AboutTraining = lazy(() => import('./about-training'))
const Patient = lazy(() => import('./patient'))
const Patients = lazy(() => import('./patients'))
const ProfilePage = lazy(() => import('./profile'))
const Achievements = lazy(() => import('./achievements'))
const Training = lazy(() => import('./training'))
const TrainingsPlan = lazy(() => import('./trainings-plan'))
const DoctorTrainings = lazy(() => import('./doctor-trainings'))
const Doctor = lazy(() => import('./specialist'))
const Doctors = lazy(() => import('./doctors'))
const Info = lazy(() => import('./information'))

export default function Routing() {
  return (
    <DefaultLayout>
      <Menu />
      <Routes>
        <Route path={PageRoutes.Page404} element={<Error404 />} />
        <Route
          path={PageRoutes.Login}
          element={
            <ProtectedRoute>
              <LoginPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.RestorePassword}
          element={
            <ProtectedRoute>
              <RestorePassword />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.Patient}
          element={
            <ProtectedRoute rolesPage={['doctor', 'admin']}>
              <Patient />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.DoctorTrainings}
          element={
            <ProtectedRoute rolesPage={['doctor', 'admin']}>
              <DoctorTrainings />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.Home}
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.Patients}
          element={
            <ProtectedRoute rolesPage={['doctor', 'admin']}>
              <Patients />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.History}
          element={
            <ProtectedRoute rolesPage={['patient', 'doctor']}>
              <History />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.AboutTraining}
          element={
            <ProtectedRoute rolesPage={['patient', 'doctor']}>
              <AboutTraining />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.Profile}
          element={
            <ProtectedRoute rolesPage={['patient', 'doctor']}>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.Achievements}
          element={
            <ProtectedRoute>
              <Achievements />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.Training}
          element={
            <ProtectedRoute rolesPage={['patient', 'doctor']}>
              <Training />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.TrainingsPlan}
          element={
            <ProtectedRoute rolesPage={['patient']}>
              <TrainingsPlan />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.Doctor}
          element={
            <ProtectedRoute rolesPage={['patient', 'admin']}>
              <Doctor />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.Doctors}
          element={
            <ProtectedRoute rolesPage={['admin']}>
              <Doctors />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.Info}
          element={
            <ProtectedRoute rolesPage={['patient', 'doctor']}>
              <Info />
            </ProtectedRoute>
          }
        />
      </Routes>
    </DefaultLayout>
  )
}
