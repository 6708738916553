import { SVGProps } from 'react'

export const SpotBottom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="750"
    height="555"
    viewBox="0 0 750 555"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M260.5 226C231.7 110.4 -7.16666 27.5 -123 0.5V654.5C113.833 659.833 607.8 669.6 689 666C790.5 661.5 752 485.5 662 476.5C572 467.5 296.5 370.5 260.5 226Z"
      fill="#EBF8FF"
    />
  </svg>
)
