import { Flex, useTheme, useToast } from '@chakra-ui/react'
import MenuButton from 'features/menu-button/ui/MenuButton'
import { useNavigate, useMatch } from 'react-router-dom'
import { CircleUserRound, Info, LogOut } from 'shared/iconpack'
import { PageRoutes } from 'shared/config/pages/PageRoutes'
import { logout } from 'entities/user/api'
import { useUserStatusStore } from 'entities/user/model'
import { Box } from 'shared/ui'

function MenuBottom({ type }: { type: 'patient' | 'doctor' | 'admin' }) {
  const navigate = useNavigate()
  const theme = useTheme()
  const gray200 = theme.colors.gray['200']
  const blue800 = theme.colors.blue['800']
  const logOut = useMatch(PageRoutes.Login)
  const isProfile = useMatch(PageRoutes.Profile)
  const isInfo = useMatch(PageRoutes.Info)
  const { removeUserStatus } = useUserStatusStore()
  const toast = useToast()

  const exitHandler = () => {
    removeUserStatus()
    localStorage.removeItem('refresh')
    navigate(PageRoutes.Login)
    logout().catch(() => {
      toast({
        position: 'bottom-right',
        title: 'Ошибка',
        description: 'Не удалось выполнить выход',
        status: 'error',
        duration: 9000,
        isClosable: true,
        variant: 'top-accent',
      })
    })
  }

  return (
    <Flex flexDir={'column'} gap={'20px'}>
      <MenuButton
        onClick={() => navigate(PageRoutes.Profile)}
        isShow={type != 'admin'}
      >
        <Box w={{ sm: '20px', base: '20px', md: '25px', lg: '30px' }}>
          <CircleUserRound
            width={'100%'}
            height={'100%'}
            color={isProfile ? blue800 : `${gray200}`}
          />
        </Box>
      </MenuButton>
      <MenuButton
        onClick={() => navigate(PageRoutes.Info)}
        isShow={type != 'admin'}
      >
        <Box w={{ sm: '20px', base: '20px', md: '25px', lg: '30px' }}>
          <Info
            width={'100%'}
            height={'100%'}
            color={isInfo ? blue800 : `${gray200}`}
          />
        </Box>
      </MenuButton>
      <MenuButton onClick={() => exitHandler()} isShow={true}>
        <Box w={{ sm: '20px', base: '20px', md: '25px', lg: '30px' }}>
          <LogOut
            width={'100%'}
            height={'100%'}
            color={logOut ? blue800 : `${gray200}`}
          />
        </Box>
      </MenuButton>
    </Flex>
  )
}

export default MenuBottom
