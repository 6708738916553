import { Flex, useTheme } from '@chakra-ui/react'
import MenuButton from 'features/menu-button/ui/MenuButton'
import { useNavigate, useMatch } from 'react-router-dom'
import {
  Home,
  Patients,
  Exercises,
  History,
  Stethoscope,
} from 'shared/iconpack'
import { PageRoutes } from 'shared/config/pages/PageRoutes'
import { Box } from 'shared/ui'
import { useStatisticsStatusStore } from 'entities/statistics/model'

function MenuTop({ type }: { type: 'patient' | 'doctor' | 'admin' }) {
  const navigate = useNavigate()
  const theme = useTheme()
  const setHistorySearch = useStatisticsStatusStore(
    (state) => state.setHistorySearch
  )
  const gray200 = theme.colors.gray['200']
  const blue800 = theme.colors.blue['800']
  const isHome = useMatch(PageRoutes.Home)
  const doctors = useMatch(PageRoutes.Doctors)
  const patients = useMatch(PageRoutes.Patients)
  const exercises = useMatch(PageRoutes.TrainingsPlan)
  const history = useMatch(PageRoutes.History)
  const doctorTainings = useMatch(PageRoutes.DoctorTrainings)
  return (
    <Flex flexDir={'column'} gap={'20px'} mt={'35px'}>
      <MenuButton
        onClick={() => navigate(PageRoutes.Home)}
        isShow={type === 'patient'}
      >
        <Box w={{ sm: '20px', base: '20px', md: '25px', lg: '30px' }}>
          <Home
            width={'100%'}
            height={'100%'}
            color={isHome ? blue800 : `${gray200}`}
          />
        </Box>
      </MenuButton>
      <MenuButton
        onClick={() => navigate(PageRoutes.Doctors)}
        isShow={type === 'admin'}
      >
        <Box w={{ sm: '20px', base: '20px', md: '25px', lg: '30px' }}>
          <Stethoscope
            width={'100%'}
            height={'100%'}
            color={doctors ? blue800 : `${gray200}`}
          />
        </Box>
      </MenuButton>
      <MenuButton
        onClick={() => navigate(PageRoutes.Patients)}
        isShow={type === 'doctor' || type === 'admin'}
      >
        <Box w={{ sm: '20px', base: '20px', md: '25px', lg: '30px' }}>
          <Patients
            width={'100%'}
            height={'100%'}
            color={patients ? blue800 : `${gray200}`}
          />
        </Box>
      </MenuButton>
      <MenuButton
        onClick={() => navigate(PageRoutes.TrainingsPlan)}
        isShow={type === 'patient'}
      >
        <Box w={{ sm: '20px', base: '20px', md: '25px', lg: '30px' }}>
          <Exercises
            width={'100%'}
            height={'100%'}
            color={exercises ? blue800 : `${gray200}`}
          />
        </Box>
      </MenuButton>
      <MenuButton
        onClick={() => navigate(PageRoutes.DoctorTrainings)}
        isShow={type === 'doctor' || type === 'admin'}
      >
        <Box w={{ sm: '20px', base: '20px', md: '25px', lg: '30px' }}>
          <Exercises
            width={'100%'}
            height={'100%'}
            color={doctorTainings ? blue800 : `${gray200}`}
          />
        </Box>
      </MenuButton>
      <MenuButton
        onClick={() => {
          navigate(PageRoutes.History)
          setHistorySearch('')
        }}
        isShow={type === 'doctor' || type === 'patient'}
      >
        <Box w={{ sm: '20px', base: '20px', md: '25px', lg: '30px' }}>
          <History
            width={'100%'}
            height={'100%'}
            color={history ? blue800 : `${gray200}`}
          />
        </Box>
      </MenuButton>
    </Flex>
  )
}

export default MenuTop
