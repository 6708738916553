import { Button } from '@chakra-ui/react'
import { IMenuButton } from './type'

function MenuButton({ children, onClick, isShow }: IMenuButton) {
  if (!isShow) return null
  return (
    <Button
      onClick={onClick}
      bg="transparent"
      border="none"
      p="0"
      _hover={{ bg: 'transparent' }}
      _active={{ bg: 'transparent' }}
      _focus={{ boxShadow: 'none' }}
    >
      {children}
    </Button>
  )
}

export default MenuButton
