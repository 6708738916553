import { ThemeComponents } from '@chakra-ui/react'
import {
  ButtonTheme,
  CheckboxTheme,
  TextTheme,
  TooltipTheme,
  HeadingTheme,
  AlertTheme,
  TabsTheme,
  InputTheme,
  switchTheme,
} from 'shared/ui'

export const components: ThemeComponents = {
  Button: ButtonTheme,
  Checkbox: CheckboxTheme,
  Tooltip: TooltipTheme,
  Text: TextTheme,
  Heading: HeadingTheme,
  Alert: AlertTheme,
  Tabs: TabsTheme,
  Input: InputTheme,
  Switch: switchTheme,
}
