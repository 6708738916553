import axios from 'shared/api/axios'
import { CreatePatientAccount, CreateDoctorAccount } from '../types'

export function login(username: string, password: string) {
  return axios.post(
    '/api/v1/auth/login',
    {
      username,
      password,
    },
    {
      withCredentials: true,
    }
  )
}

export function logout() {
  return axios.post('/api/v1/auth/logout', {
    withCredentials: true,
  })
}

export function getUserInfo() {
  return axios.get('/api/v1/users/me', {
    withCredentials: true,
  })
}

export function changeAvatar(file: File) {
  const formData = new FormData()
  formData.append('image', file)

  return axios.post('/api/v1/users/me/image', formData, {
    withCredentials: true,
  })
}

export function getPatientsInfo() {
  return axios.get('/api/v1/patients/me', {
    withCredentials: true,
  })
}

export function getDoctorInfo({ id }: { id: string | undefined }) {
  return axios.get(`/api/v1/doctors/${id}`, {
    withCredentials: true,
  })
}

export function getDoctorsInfo() {
  return axios.get(`/api/v1/doctors`, {
    withCredentials: true,
  })
}

export function getDoctorRating(
  {
    id,
  }: {
    id: string | undefined
  },
  verbose: boolean = false
) {
  const url = verbose
    ? `/api/v1/doctors/${id}/rate?verbose=true`
    : `/api/v1/doctors/${id}/rate`
  return axios.get(url, {
    withCredentials: true,
  })
}

export function changeRating(
  {
    id,
  }: {
    id: string | undefined
  },
  rating: number
) {
  return axios.post(
    `/api/v1/doctors/${id}/rate`,
    {
      rating,
    },
    {
      withCredentials: true,
    }
  )
}

export function changePassword(old_password: string, new_password: string) {
  return axios.put(
    '/api/v1/auth/change-password',
    {
      old_password,
      new_password,
    },
    {
      withCredentials: true,
    }
  )
}

export function getPatients({
  limit = 100,
  offset = 0,
}: {
  limit?: number
  offset?: number
}) {
  return axios.get(
    `/api/v1/patients?offset=${offset}&limit=${limit}`, // TODO: это не то
    {
      withCredentials: true,
    }
  )
}

export function createPatient(options: CreatePatientAccount) {
  return axios.post('/api/v1/patients/register', options, {
    withCredentials: true,
  })
}

export function getDoctors({
  limit = 100,
  offset = 0,
}: {
  limit?: number
  offset?: number
}) {
  return axios.get(`/api/v1/doctors?offset=${offset}&limit=${limit}`, {
    withCredentials: true,
  })
}

export function createDoctor(options: CreateDoctorAccount) {
  return axios.post('/api/v1/doctors/register', options, {
    withCredentials: true,
  })
}

export function recovery(login: string) {
  return axios.post(
    '/api/v1/auth/recovery',
    {
      login,
    },
    {
      withCredentials: true,
    }
  )
}

export function setNewPassword(code: string, new_password: string) {
  return axios.post(
    '/api/v1/auth/recovery/change-password',
    {
      code,
      new_password,
    },
    {
      withCredentials: true,
    }
  )
}

export function getExists(login_data: string) {
  return axios.get(`/api/v1/users/exists/${login_data}`, {
    withCredentials: true,
  })
}
