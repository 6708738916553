import { defineStyleConfig } from '@chakra-ui/react'

export const TabsTheme = defineStyleConfig({
  baseStyle: {
    variant: 'colorful',
    colorScheme: 'white',
    border: 'none',
    root: {
      color: '#B0BDD2',
      border: 'transparent',
      underline: 'none',
    },
    tab: {
      justifyContent: 'normal',
      transitionProperty: 'null',
      transitionDuration: 'null',
      width: '160px',
      underline: 'transperent',
      textAlign: 'left',
      color: '#B0BDD2',
      border: 'none',
      _selected: {
        underline: 'transperent',
        color: 'blue.800',
        border: 'none',
        borderBottom: 'none',
        borderTopRadius: 'lg',
        bg: 'white',
      },
    },
  },
})
