import { create } from 'zustand'
import { CreateTrainingState } from '../types'
import { CreateStep, Exercise } from '../types/Training'

export const useCreateTrainingStore = create<CreateTrainingState>()((set) => ({
  id: null,
  isEdit: false,
  isDelete: false,
  updatedAllSteps: [] as Array<CreateStep>,
  name: null,
  description: null,
  exercises: [] as Array<Exercise>,
  setId: (id) => set(() => ({ id })),
  setEdit: (isEdit) => set(() => ({ isEdit })),
  setIsDelete: (isDelete) => set(() => ({ isDelete })),
  setUpdatedAllSteps: (updatedAllSteps) => set(() => ({ updatedAllSteps })),
  setName: (name) => set(() => ({ name })),
  setDescription: (description) => set(() => ({ description })),
  setExercises: (exercises) =>
    set(() => ({
      exercises,
    })),
  removeExercise: (id) =>
    set((state) => ({
      exercises: state.exercises.filter((exercise) => exercise.id !== id),
    })),
  removeExerciseSlug: (slug) =>
    set((state) => ({
      exercises: state.exercises.filter((exercise) => exercise.slug !== slug),
    })),
  addExercise: (exercise) =>
    set((state) => {
      const initialMaxId = -1
      const maxId = state.exercises.reduce(
        (max, item) => Math.max(max, item.id || 0),
        initialMaxId
      )
      return {
        exercises: [
          ...state.exercises,
          {
            ...exercise,
            id: maxId + 1,
          },
        ],
      }
    }),
  setTrainingTime: (id, newTime) =>
    set((state) => ({
      exercises: state.exercises.map((item) =>
        item.id === id ? { ...item, time: Math.max(10, newTime) } : item
      ),
    })),
  setRepetitionsNumber: (id, newNum) =>
    set((state) => ({
      exercises: state.exercises.map((item) =>
        item.id === id ? { ...item, repeats: newNum } : item
      ),
    })),
  setTrainingTimeForAll: (newTime) =>
    set((state) => ({
      exercises: state.exercises.map((item) =>
        item.name !== 'Перерыв' ? { ...item, time: newTime } : item
      ),
    })),
  setRepetitionsNumberForAll: (newNum) =>
    set((state) => ({
      exercises: state.exercises.map((item) =>
        item.name !== 'Перерыв' ? { ...item, repeats: newNum } : item
      ),
    })),
  clear: () => set(() => ({ exercises: [] })),
}))
