import { AlertProps, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { alertAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys)

const baseStyle = definePartsStyle((props: AlertProps) => {
  const { status } = props

  const successBase = status === 'success' && {
    container: {
      background: 'green.100',
      color: 'green.200',
    },
  }

  const errorBase = status === 'error' && {
    container: {
      background: 'red.100',
      color: 'red.500',
    },
  }

  const infoBase = status === 'info' && {
    container: {
      background: 'white',
      color: 'blue.800',
    },
  }

  return {
    ...successBase,
    ...errorBase,
    ...infoBase,
  }
})

export const AlertTheme = defineMultiStyleConfig({ baseStyle })
