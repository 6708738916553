import { SVGProps } from 'react'

export const AsideLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.89944 9.60526C1.69088 9.86842 1.41527 10 1.07263 10C0.774674 10 0.521415 9.89766 0.312849 9.69298C0.104283 9.4883 0 9.23977 0 8.94737C0 8.66959 0.0744879 8.4576 0.223464 8.3114L5.65363 1.14035C5.90689 0.804094 6.24209 0.533627 6.65922 0.328948C7.07635 0.109649 7.52328 0 8 0C8.44693 0 8.87151 0.0950293 9.27374 0.285088C9.67598 0.475147 10.0037 0.738305 10.257 1.07456L15.6872 8.20175C15.7616 8.31871 15.8287 8.43567 15.8883 8.55263C15.9628 8.66959 16 8.80117 16 8.94737C16 9.23977 15.8957 9.4883 15.6872 9.69298C15.4786 9.89766 15.2253 10 14.9274 10C14.5549 10 14.2719 9.86111 14.0782 9.58333L8.87151 2.76316C8.76723 2.6462 8.63315 2.52193 8.46927 2.39035C8.3203 2.24415 8.16387 2.17105 8 2.17105C7.83613 2.17105 7.67225 2.24415 7.50838 2.39035C7.3594 2.52193 7.24022 2.6462 7.15084 2.76316L1.89944 9.60526Z"
      fill="#54C1FB"
    />
    <path
      d="M7.01942 6.63302C6.75127 6.89618 6.61719 7.21781 6.61719 7.59793C6.61719 7.97805 6.75127 8.29968 7.01942 8.56284C7.28758 8.826 7.61533 8.95758 8.00266 8.95758C8.39 8.95758 8.71775 8.826 8.9859 8.56284C9.25406 8.29968 9.38814 7.97805 9.38814 7.59793C9.38814 7.21781 9.25406 6.89618 8.9859 6.63302C8.71775 6.36986 8.39 6.23828 8.00266 6.23828C7.61533 6.23828 7.28758 6.36986 7.01942 6.63302Z"
      fill="#A5B4CB"
    />
  </svg>
)
