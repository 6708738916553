import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { AccountStoreState } from '../types'

export const useUserStatusStore = create(
  persist<AccountStoreState>(
    (set) => ({
      role: 'patient',
      isAuth: false,
      id: '',
      start_date: '',
      avatar_url: '',
      show_instruction: true,
      show_video: true,
      isRecovery: false,
      setUserStatus: (role) =>
        set(() => ({
          role,
          isAuth: true,
        })),
      setUserId: (id) =>
        set(() => ({
          id,
        })),
      setUserAvatar: (avatar_url) =>
        set(() => ({
          avatar_url,
        })),
      removeUserStatus: () =>
        set(() => ({
          role: 'patient',
          isAuth: false,
        })),
      setShowInstruction: (show_instruction) =>
        set(() => ({
          show_instruction,
        })),
      setShowVideo: (show_video) =>
        set(() => ({
          show_video,
        })),
      setStartDate: (date) => {
        set((prev) => ({
          ...prev,
          start_date: date,
        }))
      },
      setRecovery: (isRecovery) =>
        set(() => ({
          isRecovery,
        })),
    }),
    {
      name: 'account-type',
    }
  )
)
