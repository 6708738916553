import { SVGProps } from 'react'

export const SpotTop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="383"
    height="372"
    viewBox="0 0 383 372"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.3849 3.5C-46.2151 -119.3 128.718 -235.333 223.385 -278H469.385C473.885 -87.1667 476.585 296.8 451.385 306C419.885 317.5 304.885 385 203.385 369C101.885 353 54.3848 263 54.3848 227C54.3848 191 83.3849 157 11.3849 3.5Z"
      fill="#EBF8FF"
    />
  </svg>
)
