import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

export const CheckboxTheme = defineStyleConfig({
  baseStyle: {
    control: defineStyle({
      border: '1px',
      _checked: {
        backgroundColor: 'lightblue.500',
        borderColor: 'lightblue.500',
        _hover: {
          opacity: 0.9,
        },
        _disabled: {
          backgroundColor: 'light.200',
          borderColor: 'light.200',
        },
      },
    }),
    label: {
      color: 'blue.800',
      fontWeight: 300,
      fontSize: '14px',
    },
  },
})
