import { SVGProps } from 'react'

export const Stethoscope = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.66667"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <g clip-path="url(#clip0_1870_1602)">
      <path d="M4 1.91662C4.00297 1.96898 4.02233 2.01909 4.05535 2.05984C4.08836 2.10059 4.13335 2.12992 4.18396 2.14369C4.23457 2.15746 4.28822 2.15497 4.33733 2.13657C4.38644 2.11816 4.42852 2.08478 4.45761 2.04114C4.4867 1.9975 4.50134 1.94582 4.49944 1.89341C4.49754 1.841 4.4792 1.79051 4.44703 1.74909C4.41485 1.70768 4.37047 1.67743 4.32015 1.66263C4.26984 1.64783 4.21614 1.64922 4.16667 1.66662H3.33333C2.89131 1.66662 2.46738 1.84222 2.15482 2.15478C1.84226 2.46734 1.66667 2.89126 1.66667 3.33329V7.49996C1.66667 8.82604 2.19345 10.0978 3.13113 11.0355C4.06882 11.9732 5.34059 12.5 6.66667 12.5C7.99275 12.5 9.26452 11.9732 10.2022 11.0355C11.1399 10.0978 11.6667 8.82604 11.6667 7.49996V3.33329C11.6667 2.89126 11.4911 2.46734 11.1785 2.15478C10.866 1.84222 10.442 1.66662 10 1.66662H9.16667C9.15025 1.68304 9.13723 1.70253 9.12835 1.72397C9.11946 1.74542 9.11489 1.76841 9.11489 1.79162C9.11489 1.81484 9.11946 1.83782 9.12835 1.85927C9.13723 1.88072 9.15025 1.90021 9.16667 1.91662C9.18308 1.93304 9.20257 1.94606 9.22402 1.95494C9.24547 1.96383 9.26845 1.9684 9.29167 1.9684C9.31488 1.9684 9.33787 1.96383 9.35932 1.95494C9.38076 1.94606 9.40025 1.93304 9.41667 1.91662" />
      <path d="M6.66667 12.5V13.3333C6.66667 14.6594 7.19345 15.9312 8.13113 16.8689C9.06882 17.8065 10.3406 18.3333 11.6667 18.3333C12.9928 18.3333 14.2645 17.8065 15.2022 16.8689C16.1399 15.9312 16.6667 14.6594 16.6667 13.3333V10" />
      <path d="M16.6667 9.99996C17.5871 9.99996 18.3333 9.25377 18.3333 8.33329C18.3333 7.41282 17.5871 6.66663 16.6667 6.66663C15.7462 6.66663 15 7.41282 15 8.33329C15 9.25377 15.7462 9.99996 16.6667 9.99996Z" />
    </g>
    <defs>
      <clipPath id="clip0_1870_1602">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
