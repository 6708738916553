import { Flex } from '@chakra-ui/react'
import { AsideLogo } from 'shared/iconpack'
import { useMatch } from 'react-router-dom'
import { PageRoutes } from 'shared/config/pages/PageRoutes'
import { Aside } from 'shared/ui/aside'
import MenuTop from 'widgets/Menu/MenuTop/MenuTop'
import MenuBottom from 'widgets/Menu/MenuBottom/MenuBottom'
import { useUserStatusStore } from 'entities/user/model'

function Menu() {
  const isLogin = useMatch(PageRoutes.Login)
  const isTraining = useMatch(PageRoutes.Training)
  const { isAuth, role } = useUserStatusStore()
  if (isLogin || !isAuth || isTraining) return null
  return (
    <Aside>
      <AsideLogo />
      <Flex flexDir={'column'} justifyContent={'space-between'} h={'100%'}>
        <MenuTop type={role} />
        <MenuBottom type={role} />
      </Flex>
    </Aside>
  )
}

export { Menu }
