import { Flex } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { SpotBottom, SpotTop } from 'shared/iconpack'

export const AuthLayout = ({ children }: { children: ReactNode }) => (
  <Flex
    w="100vw"
    h="100vh"
    direction="column"
    position="relative"
    alignItems="center"
    justifyContent="center"
    background="white"
    zIndex={0}
  >
    <SpotTop style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }} />
    {children}
    <SpotBottom
      style={{ position: 'absolute', bottom: 0, left: 0, zIndex: -1 }}
    />
  </Flex>
)
