import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { StatisticsStoreState } from '../types'

export const useStatisticsStatusStore = create(
  persist<StatisticsStoreState>(
    (set) => ({
      historySearch: '',
      setHistorySearch: (historySearch) =>
        set(() => ({
          historySearch,
        })),
    }),
    {
      name: 'statistics',
    }
  )
)
